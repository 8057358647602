@import '~@/styles/viewport.scss';
@import '~@/styles/mediaQuery.scss';
@import '~@/styles/tailwind.scss';

.show-pc {
  @include mediaL {
    display: block;
  }
}

.hide-pc {
  @include mediaL {
    display: none;
  }
}

.show-mobile {
  @include mediaS {
    display: block;
  }
}

.hide-mobile {
  @include mediaS {
    display: none;
  }
}

.rc-dialog-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
}
